import { Injectable } from "@angular/core";
import { JwtAuthGuard, JwtAuthService } from "@devlearning/jwt-auth";
import { AuthenticationToken } from "../models/authentication-token";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable, catchError, map, of } from "rxjs";
import { UserService } from "../services/user.service";
import { UserTypeEnum } from "../autogenerated/model.autogenerated";
import { MENU_HOTAC, MENU_SUPPLIER_FOOD_SERVICE_COMPANY, MENU_SUPPLIER_TRANSPORT_COMPANY, MENU_TICKET } from "../utils/constants";

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends JwtAuthGuard<AuthenticationToken> {

  constructor(
    private readonly _router: Router,
    private readonly _jwtAuth: JwtAuthService<AuthenticationToken>,
    private readonly _user: UserService,
  ) {
    super(_jwtAuth);
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivateBase(next, state)
      .pipe(
        map(isLoggedIn => {
          if (!isLoggedIn) {
            console.debug('%cGoTo Login - GUARD 1', 'font-size: 30px; color: red');
            this._router.navigateByUrl('/login');
            return false;
          }
          
          const appSection = this._getAppSection(state);
          
          if(appSection == null || appSection == ''){
            const index = this._getIndexPageByUserType(this._user.datiUtente?.idTipoUtente!);
            this._router.navigate([index]);
          }
          
          return true;
        }),
        catchError(e => {
          console.debug('%cGoTo Login - GUARD 3', 'font-size: 30px; color: red')
          this._router.navigateByUrl('/login');
          return of(false);
        })
      );
  }

  private _getAppSection(state: RouterStateSnapshot) {
    let appSection: string | null = state.url;

    if (appSection.startsWith('/')) {
      appSection = appSection.substring(1);
    }

    const slashIndex = appSection.indexOf('/')
    if (slashIndex >= 0) {
      appSection = appSection.substring(0, slashIndex);
    }

    return appSection;
  }
  
  private _getIndexPageByUserType(userType: UserTypeEnum) {
    switch (userType) {
      case UserTypeEnum.SUPER_ADMIN:
        return MENU_TICKET;
      case UserTypeEnum.CUSTOMER_AIRLINE_COMPANY_ADMIN:
        return MENU_TICKET;
      case UserTypeEnum.CUSTOMER_AIRLINE_COMPANY_USER:
        return MENU_TICKET;
      case UserTypeEnum.SUPPLIER_TRANSPORT_ADMIN:
        return MENU_SUPPLIER_TRANSPORT_COMPANY;
      // case UserTypeEnum.PASSENGER:
      //   return APP_PASSEGGERI;
      case UserTypeEnum.SUPPLIER_FOOD_SERVICE_COMPANY_ADMIN:
        return MENU_SUPPLIER_FOOD_SERVICE_COMPANY;
      case UserTypeEnum.SUPPLIER_FOOD_SERVICE_COMPANY_USER:
        return MENU_SUPPLIER_FOOD_SERVICE_COMPANY;
      case UserTypeEnum.SUPPLIER_HOTEL_HOTAC:
        return MENU_HOTAC;
      default:
        return null;
    }
  }

  // private _checkAppSectionByUserType(appSection: string | null, userType: UserTypeEnum) {
  //   switch (userType) {
  //     case UserTypeEnum.SUPER_ADMIN:
  //       return appSection == APP_ADMIN_LAYOVER;
  //     case UserTypeEnum.CUSTOMER_AIRLINE_COMPANY_ADMIN:
  //       return appSection == APP_CUSTOMER_AIRLINE_COMPANY_ADMIN;
  //     case UserTypeEnum.CUSTOMER_AIRLINE_COMPANY_USER:
  //       return appSection == APP_HANDLER;
  //     // case UserTypeEnum.HOTEL:
  //     //   return appSection == APP_HOTEL;
  //     case UserTypeEnum.SUPPLIER_TRANSPORT_ADMIN:
  //       return appSection == APP_TRASPORTI;
  //     case UserTypeEnum.PASSENGER:
  //       return appSection == APP_PASSEGGERI;
  //     case UserTypeEnum.SUPPLIER_FOOD_SERVICE_COMPANY_ADMIN:
  //       return appSection == APP_FOOD_SERVICE_COMPANY_ADMIN;
  //     case UserTypeEnum.SUPPLIER_FOOD_SERVICE_COMPANY_USER:
  //       return appSection == APP_FOOD_SERVICE_COMPANY_USER;
  //     case UserTypeEnum.SUPPLIER_HOTEL_HOTAC:
  //       return appSection == APP_HOTAC;
  //       case UserTypeEnum.OPERATOR:
  //         return appSection == APP_LAYOVER_USERS;
  //     default:
  //       return null;
  //   }
  // }

  // private _getAppSectionByUserType(userType: UserTypeEnum) {
  //   switch (userType) {
  //     case UserTypeEnum.SUPER_ADMIN:
  //       return APP_ADMIN_LAYOVER;
  //     case UserTypeEnum.CUSTOMER_AIRLINE_COMPANY_ADMIN:
  //       return APP_CUSTOMER_AIRLINE_COMPANY_ADMIN;
  //     case UserTypeEnum.CUSTOMER_AIRLINE_COMPANY_USER:
  //       return APP_HANDLER;
  //     // case UserTypeEnum.HOTEL:
  //     //   return APP_HOTEL;
  //     case UserTypeEnum.SUPPLIER_TRANSPORT_ADMIN:
  //       return APP_TRASPORTI;
  //     case UserTypeEnum.PASSENGER:
  //       return APP_PASSEGGERI;
  //     case UserTypeEnum.SUPPLIER_FOOD_SERVICE_COMPANY_ADMIN:
  //       return APP_FOOD_SERVICE_COMPANY_ADMIN;
  //     case UserTypeEnum.SUPPLIER_FOOD_SERVICE_COMPANY_USER:
  //       return APP_FOOD_SERVICE_COMPANY_USER;
  //     case UserTypeEnum.SUPPLIER_HOTEL_HOTAC:
  //       return APP_HOTAC_USER;
  //     default:
  //       return null;
  //   }
  // }
}
