import { CdkAccordionModule } from '@angular/cdk/accordion';
import { LayoutModule } from '@angular/cdk/layout';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtAuthModule, StorageType } from '@devlearning/jwt-auth';
import { MutexFastLockModule } from '@devlearning/mutex-fast-lock';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { exhaustMap, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MessageComponent } from './core/components/message/message.component';
import { GlobalErrorHandler } from './core/global-error-handler';
import { HttpRequestAddIanaNameInterceptor } from './core/interceptors/http-request-add-iana-name.interceptor';
import { CacheAirportsService } from './core/services/cache-airports.service';
import { EnumsService } from './core/services/enums.service';
import { GoogleApiService } from './core/services/google-api.service';
import { LanguageService } from './core/services/language.service';
import { UserService } from './core/services/user.service';
import { LinguaModule } from './sharedmodules/lingua/lingua.module';
import { LoaderModule } from './sharedmodules/loader/loader.module';
import { AppStateModule } from './store/app/app-state.module';

import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeEnExtra from '@angular/common/locales/extra/en';
import localeItExtra from '@angular/common/locales/extra/it';
import localeIt from '@angular/common/locales/it';

registerLocaleData(localeEn, 'en-US', localeEnExtra);
registerLocaleData(localeIt, 'it-IT', localeItExtra);


@NgModule({
  declarations: [
    AppComponent,
    MessageComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    MatRadioModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      defaultLanguage: environment.DEFAULT_LANGUAGE,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    MutexFastLockModule.forRoot({
      lockPrefix: '_MUTEX_LOCK',
      timeout: 30000,
      debugEnabled: false
    }),
    JwtAuthModule.forRoot({
      logLevel: 1,
      tokenUrl: environment.TOKEN_URL,
      refreshUrl: environment.REFRESH_URL,
      useManualInitialization: true,
      storageType: StorageType.SESSION_STORAGE
    }),
    BrowserAnimationsModule,
    MatDialogModule,
    MatButtonModule,
    MatMenuModule,
    CdkAccordionModule,
    LinguaModule,
    LoaderModule,
    LayoutModule,
    EffectsModule.forRoot([]),
    AppStateModule,
    StoreDevtoolsModule.instrument({
      name: 'layover-app-admin',
      maxAge: 25,
      logOnly: environment.production
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      multi: true,
      deps: [
        LanguageService,
        UserService,
        EnumsService,
        CacheAirportsService,
        GoogleApiService,
      ]
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { panelClass: 'mat-dialog-override' } },
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestAddIanaNameInterceptor, multi: true },
    {
      provide: LOCALE_ID,
      useValue: sessionStorage.getItem('locale') || 'it-IT'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

function initializeAppFactory(
  language: LanguageService,
  user: UserService,
  enums: EnumsService,
  cacheAirports: CacheAirportsService,
  googleApi: GoogleApiService): () => Observable<any> {
  return () => enums.initialize()
    .pipe(
      exhaustMap(x => user.initialize()),
      exhaustMap(x => language.initialize(x.datiUtente?.lingua!)),
      tap(x => moment.locale(x.languageCultureName?.substring(0, 2))),
      exhaustMap(x => cacheAirports.initialize()),
      tap(x => googleApi.initialize(environment.googleApiKey, "it-IT")),
      map(x => true),
      tap(x => console.info("Initialization completed"))
    );
}


